<template>
  <div>
    <search-form :items="items" @fnSearch="handleFnSearch" />
    <grid
      ref="grid"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1200, y: gridHeight }"
    >
      <template #status="{ record }">
        <a-tag :color="record ? 'green' : 'orange'">
          {{ record ? '启用' : '草稿' }}
        </a-tag>
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
    <a-modal
      :visible="centerDialogVisible"
      :title="dialogTitle"
      :width="800"
      :maskClosable="false"
      centered
      @cancel="handleCancel"
      @ok="addMailItem"
    >
      <a-row :gutter="20">
        <a-col :span="10">
          <a-form
            ref="addParams"
            :model="addParams"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 17 }"
          >
            <a-form-item name="name" label="名称">
              <a-input
                v-model:value="addParams.name"
                placeholder="请输入名称"
              />
            </a-form-item>
            <a-form-item name="addresser" label="发送人昵称">
              <a-input
                v-model:value="addParams.addresser"
                placeholder="请输入发送人昵称"
              />
            </a-form-item>
            <a-form-item name="type" label="模板类型">
              <a-select
                v-model:value="addParams.type"
                placeholder="请选择模板类型"
              >
                <a-select-option
                  v-for="item in tplTypeOptions"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="msgId" label="邮件模版">
              <a-select
                v-model:value="addParams.msgId"
                placeholder="请选择邮件模版"
                @change="msgIdChange"
              >
                <a-select-option
                  v-for="item in messageTplOptions"
                  :key="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a @click="tplManage">模板管理</a>
            </a-form-item>
            <a-form-item name="status" label="启用状态">
              <a-radio-group v-model:value="addParams.status">
                <a-radio :value="true">启用</a-radio>
                <a-radio :value="false">草稿</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :span="14">
          <div class="mac-wrapper">
            <div class="mac-bar">
              <ul class="mac-list">
                <li class="mac-item"></li>
                <li class="mac-item"></li>
                <li class="mac-item"></li>
              </ul>
            </div>
            <iframe
              :src="previewUrl"
              frameborder="0"
              width="100%"
              height="350"
            />
          </div>
        </a-col>
      </a-row>
    </a-modal>
    <a-modal
      :visible="msgTplListDialogVisible"
      title="消息模板列表"
      :width="600"
      centered
      :maskClosable="false"
      :footer="null"
      @cancel="msgTplListDialogVisible = false"
    >
      <a-button type="primary" class="mb-10" @click="addMsgTpl">
        <template #icon><plus-outlined /></template>
        新增
      </a-button>
      <a-table
        bordered
        :data-source="messageTplOptions"
        :columns="tmplColumns"
        :loading="msgTplDialogLoading"
        :pagination="false"
        rowKey="id"
        size="small"
        :scroll="{ y: 400 }"
      >
        <template #name="{ text, record }">
          <editable-cell
            :text="text"
            @change="msgTplSendParams(record.key, 'name', $event)"
          />
        </template>
        <template #url="{ record }">
          <span v-if="!msgTplParams[record.id]">
            {{ record.url ? '已上传' : '未上传' }}
          </span>
          <a-upload
            class="msg-tpl-upload"
            :ref="'msgTplUpload' + record.id"
            :on-success="
              (response, file, fileList) =>
                uploadSuccess(response, file, fileList, record)
            "
            :on-error="err => uploadErr(err, record)"
            :auto-upload="false"
            :on-change="file => uploadChange(file, record)"
            :show-upload-list="false"
            :headers="{ token }"
            :action="uploadImgUrl"
          >
            <a-button type="link">
              <template #icon><UploadOutlined /></template>
              上传HTML
            </a-button>
          </a-upload>
        </template>
        <template #operation="{ record }">
          <operation :options="tmplOptions" :record="record" />
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import {
  Tag,
  Row,
  Col,
  Form,
  Select,
  Radio,
  Table,
  Upload,
} from 'ant-design-vue';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import EditableCell from '@/components/editableCell';
import marketingApi from '@/api/marketing.js';
// import readFile from '@/utils/readFile';
// import db from '@/utils/localstorage';

export default {
  name: 'RedoMail',
  components: {
    ATag: Tag,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadioGroup: Radio.Group,
    ARadio: Radio,
    ATable: Table,
    AUpload: Upload,
    SearchForm,
    Grid,
    Operation,
    EditableCell,
    PlusOutlined,
    UploadOutlined,
  },
  data() {
    return {
      items: [
        { key: 'name', label: '名称' },
        {
          key: 'status',
          label: '启用状态',
          type: 'radio',
          dataset: [
            { label: '启用', value: true },
            { label: '草稿', value: false },
            { label: '全部', value: undefined },
          ],
        },
      ],
      columns: [
        { dataIndex: 'name', title: '名称' },
        { dataIndex: 'addresser', title: '发件人昵称', width: 200 },
        {
          dataIndex: 'status',
          title: '状态',
          width: 100,
          slots: { customRender: 'status' },
        },
        { dataIndex: 'updateTime', title: '更新时间', width: 180 },
        {
          key: 'id',
          title: '操作',
          width: 128,
          fixed: 'right',
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        { type: 'add', permission: 'template:add', fnClick: this.handleEdit },
      ],
      options: [
        {
          type: 'update',
          permission: 'template:update',
          fnClick: this.editGift,
        },
        {
          type: 'preview',
          icon: 'EyeTwoTone',
          permission: 'template:preview',
          label: '预览',
          fnClick: this.previewFn,
        },
        {
          type: 'delete',
          permission: 'template:delete',
          fnClick: this.delMailItem,
        },
      ],
      url: marketingApi.emailTplUrl,
      gridHeight: document.body.clientHeight - 400,
      search: {},
      centerDialogVisible: false,
      dialogTitle: '新增邮件消息',
      tmplColumns: [
        {
          dataIndex: 'name',
          title: '名称',
          slots: { customRender: 'name' },
        },
        {
          dataIndex: 'url',
          title: '内容',
          slots: { customRender: 'url' },
        },
        {
          dataIndex: 'id',
          title: '操作',
          slots: { customRender: 'operation' },
        },
      ],
      tmplOptions: [
        {
          type: 'delete',
          permission: 'org:update',
          fnClick: this.delMessageItem,
        },
      ],
      msgTplParams: {},
      msgTplDialogLoading: false,
      previewUrl: '',
      msgTplListDialogVisible: false,
      msgTplEditSign: '',
      addParams: {
        label: 'default label',
        status: true,
        type: null,
        msgId: null,
        name: '',
        addresser: 'IP Club',
      },

      messageTplOptions: [],
      tplTypeOptions: [],

      dialogLoading: false,
      loading: false,
      multipleSelection: [],
    };
  },
  // computed: {
  //   uploadImgUrl: () =>
  //     process.env.VUE_APP_REQUEST_SERVER +
  //     '/api/file/attachment/upload?bizType=GOODS_IMAGE&isSingle=true',
  //   token: () =>
  //     process.env.NODE_ENV === 'development'
  //       ? 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3MDk0MjYyMjM0OTQ3MzY5OTMiLCJhY2NvdW50IjoiemhhbmdibyIsIm5hbWUiOiLlvKDms6IiLCJvcmdpZCI6MTAwLCJzdGF0aW9uaWQiOjY0NTIwMDkzODI4OTYwNTAyNSwiZXhwIjoxNTkwOTAxOTEzfQ.Y89cinuTGmUlP7BrFAreo4PmLKI4DN55oU8p5FojSpe2sod_Av9Ja2ZvBMIQbdLS2hurHxB83AlQn2darLUNdDhprRDmMiufb_FS9kITgKQDkZAVzehAbSyqu-xmYvIKpYZz2bYuHPN26oYPRYhwVG8AqSYs74RuOxgn0EJgeVU'
  //       : db.get('TOKEN', ''),
  // },
  watch: {
    centerDialogVisible(val) {
      if (val) return;
      this.$refs.addParams.resetFields();
      this.previewUrl = '';
    },
    msgTplListDialogVisible(val) {
      if (val) return;
      this.initMessageTplList();
    },
  },
  created() {
    this.initMessageTplList();
    this.getTplTypeList();
  },
  methods: {
    initMessageTplList() {
      marketingApi.getMessageTplList(this.$route.path).then(data => {
        this.messageTplOptions = data.records;
      });
    },
    async getTplTypeList() {
      const res = await marketingApi.getTplTypeList(this.$route.path);
      const data = res.MAILTEMPTYPE;
      if (!res) return;
      this.tplTypeOptions = Object.keys(data);
    },
    handleFnSearch(value) {
      this.search = value;
    },
    handleEdit() {
      this.centerDialogVisible = true;
      this.dialogTitle = '新建邮件消息';
    },
    handleCancel() {
      this.centerDialogVisible = false;
    },
    handleOk() {},
    delMailItem({ id }) {
      marketingApi.delMailItem('template:delete', { ids: [id] }).then(() => {
        this.$refs.grid.refreshGrid();
      });
    },
    msgIdChange(msgId) {
      const theSelectItem = this.messageTplOptions.find(
        val => val.id === msgId
      );
      this.previewUrl = theSelectItem.url;
    },

    async getList() {},
    submitUpload() {
      this.$refs.upload.submit();
    },
    async tplManage() {
      this.msgTplListDialogVisible = true;
      // this.getMessageTplList();
    },

    msgTplPreviewFn(row) {
      open(row.url);
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.getList(true);
    },

    addMailItem() {
      // this.dialogTitle = '新增邮件消息';
      this.sendParams();
    },

    async sendParams() {
      // 通过id判断是否为编辑
      const httpType = this.addParams.id ? 'editMailItem' : 'addMailItem';
      const notifyText = this.addParams.id ? '修改' : '新增';
      const code = this.addParams.id ? 'template:update' : 'template:add';

      this.dialogLoading = true;
      const res = await marketingApi[httpType](code, this.addParams);
      this.dialogLoading = false;
      Reflect.deleteProperty(this.addParams, 'id');
      this.$message({
        // title: res ? '成功' : '失败',
        message: res ? notifyText + '成功！' : notifyText + '失败！',
        type: res ? 'success' : 'error',
      });
      // 请求失败终止执行
      if (!res) return;
      // 关闭对话框  刷新列表
      this.centerDialogVisible = false;
      this.$refs.grid.refreshGrid();
    },

    // 取消新增/修改
    addCancel() {
      this.centerDialogVisible = false;
    },

    // 删除邮件消息

    // 删除模板消息
    async delMessageItem(id) {
      this.msgTplDialogLoading = true;
      const res = await marketingApi.delMessageItem('template:add', {
        ids: [id],
      });
      this.msgTplDialogLoading = false;

      this.$message({
        // title: res ? '成功' : '失败',
        message: res ? '删除成功！' : '删除失败！',
        type: res ? 'success' : 'error',
      });
      // 请求失败终止执行
      if (!res) return;

      // 刷新列表
      this.initMessageTplList();
    },

    // 修改邮件消息
    async editGift(tplData) {
      this.centerDialogVisible = true;
      this.dialogTitle = '编辑邮件消息';
      this.msgIdChange(tplData.msgId);
      this.$nextTick(function () {
        Object.assign(this.addParams, tplData);
      });
    },

    // 预览
    previewFn(row) {
      marketingApi.getMessageItem('template:preview', row.msgId).then(data => {
        window.open(data.url);
      });
    },

    statusFormatter(row, isTag) {
      switch (row.status) {
        case true:
          return isTag ? 'success' : '启用';
        case false:
          return isTag ? 'info' : '草稿';
      }
    },

    uploadSuccess(res, file, fileList, item) {
      if (res.code !== 0 && res.code !== 200)
        return this.uploadErr(res.msg, item);
      this.msgTplDialogLoading = false;
      item.url = res.data.url;
      this.addOreditMsgTplItem(item);

      this.msgTplParams[item.id] = false;
      // this.msgTplParams.url = res.data.url;
      this.$message({
        // title: '成功',
        message: '模板上传成功！',
        type: 'success',
      });
    },

    uploadErr(err) {
      this.msgTplDialogLoading = false;

      this.$message({
        // title: '失败',
        message: err,
        type: 'error',
      });
      console.error(err);
    },

    async uploadChange(res) {
      if (res.status !== 'ready') return;

      const file = res.raw;
      const isHTML = file.type === 'text/html';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isHTML) {
        return this.$message.error('上传文件只能是 HTML 格式，请重新上传!');
      }
      if (!isLt2M) {
        return this.$message.error('上传文件大小不能超过 2MB，请重新上传!');
      }

      // 插入脚本
      // const fileText = await readFile(file);
      // let txt = fileText.replace(
      //   '<script>',
      //   `<script>console.log('indeset')<\/script><script>`
      // );
      // const blob = new Blob([txt], { type: 'text/html' });
      // const htmlFile = new File([blob], 'text.html', { type: 'text/html' });

      // this.$set(this.msgTplParams, item.id, {
      //   url: URL.createObjectURL(htmlFile),
      //   name: file.name,
      //   fileList: [
      //     {
      //       name: file.name,
      //       status: 'ready',
      //       percentage: 0,
      //       size: htmlFile.size,
      //       raw: htmlFile,
      //     },
      //   ],
      // });
    },

    msgTplSendParams(row) {
      // 如果没有上传文件，则直接修改
      if (!this.msgTplParams[row.id]) return this.addOreditMsgTplItem(row);

      const uploadEl = this.$refs['msgTplUpload' + row.id][0];
      const fileList = this.msgTplParams[row.id].fileList;
      // 用于上传失败后，重置文件上传状态，可进行二次保存上传
      fileList.map(fileObj =>
        Object.assign(fileObj, { status: 'ready', percentage: 0 })
      );

      // 覆盖上传文件列表
      uploadEl.uploadFiles = fileList;
      this.msgTplDialogLoading = true;
      uploadEl.submit();
    },

    // 更新消息模板
    async addOreditMsgTplItem(row) {
      // this.$refs['msgTplUpload' + row.id][0].submit();

      const methodType =
        row.type === 'add' ? 'addMessageItem' : 'editMessageItem';
      const msgType = row.type === 'add' ? '新增' : '修改';

      // 添加默认参数
      row.label = 'default label';

      this.msgTplDialogLoading = true;
      const res = await marketingApi[methodType](row);
      this.msgTplDialogLoading = false;

      if (!res) return;
      this.$message({
        message: msgType + '成功！',
        type: 'success',
      });

      // this.msgTplParams[row.id] = {};

      this.getMessageTplList();
      this.msgTplEditSign = '';
    },

    msgTplCancelEdit() {
      this.msgTplEditSign = '';
    },

    addMsgTpl() {
      const tempId = +new Date();
      this.messageTplOptions.unshift({
        id: tempId,
        name: '',
        url: '',
        label: 'default label',
        type: 'add',
      });

      this.msgTplEditSign = tempId;
    },
  },
};
</script>
<style lang="less" scoped>
.mac-wrapper {
  height: 100%;
  box-shadow: 0px 0px 10px #aaa;
}
.mac-bar {
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #535353;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.mac-list {
  position: absolute;
  top: 0px;
  left: 15px;
  width: auto;
  height: 100%;
}
.mac-item {
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #f4a62a;
}

.mac-item:first-child {
  background-color: #e74c3c;
}

.mac-item:last-child {
  background-color: #16a085;
}
</style>
